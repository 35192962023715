
<!-- image-text-wrapper3 section start here -->
<section class="container-fluid testimonial-wrapper-2 section bg-light-blue chatbot-wrapper mb-5 mt-5" *ngIf="testimonialWrapper2Type=='botStationAi'">
    <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center heading-wrapper mb-3">
              <div class="w-75 m-auto">
                <h4 class="h2 mb-3" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" >
                    Reviews by<span class="chatbot-text-primary"> Clients</span>
                </h4>
                <p class="text-muted" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="200">
                    We will be happy to organize a 30 minute demo to showcase why the industry leaders prefer all in one for Conversational AI platform.
                </p>
              </div>
          </div>
        </div>
      <div class="row" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="500">
        <div class="col-12">
          <owl-carousel-o [options]="testimonial1">
            <ng-template carouselSlide>
              <div class="testimonial-item">
                <div class="profile-img float-start">
                    <img src="./assets/images/users/2.png" />
                </div>
                <div class="testominial-text float-end">
                  <p>
                    “The most well-known dum text is the 'Lorem Ipsum', which is said to have originated in the 16th century. ".
                  </p>
                  <div class="testi-meta mt-2">
                    <span class="float-start w-100 mb-2">
                        <span class="chatbot-text-primary">- Ana Watson </span>
                        <span> Syntel Inc</span></span>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="testimonial-item">
                <div class="profile-img float-start">
                    <img src="./assets/images/users/1.png" />
                </div>
                <div class="testominial-text float-end">
                  <p>
                    "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "
                  </p>
                  <div class="testi-meta mt-2">
                    <span class="float-start w-100 mb-2">
                        <span class="chatbot-text-primary">- Matrik Lee  </span>
                        <span> Apple Inc</span></span>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="testimonial-item">
                <div class="profile-img float-start">
                    <img src="./assets/images/users/3.png" />
                </div>
                <div class="testominial-text float-end">
                  <p>
                    "One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "
                  </p>
                  <div class="testi-meta mt-2">
                    <span class="float-start w-100 mb-2">
                        <span class="chatbot-text-primary">- Jolly Wilson  </span>
                        <span>City Bank, India</span></span>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="testimonial-item">
                <div class="profile-img float-start">
                    <img src="./assets/images/users/1.png" />
                </div>
                <div class="testominial-text float-end">
                  <p>
                    “The most well-known dum text is the 'Lorem Ipsum', which is said to have originated in the 16th century. ".
                  </p>
                  <div class="testi-meta mt-2">
                    <span class="float-start w-100 mb-2">
                        <span class="chatbot-text-primary">- Ana Watson </span>
                        <span> Syntel Inc</span>
                    </span>
                  </div>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- image-text-wrapper3 section end here -->


  <!-- image-text-wrapper3 section start here -->
<section class="container-fluid testimonial-wrapper-2 section bg-white chatbot-wrapper mt-5 pb-0" *ngIf="testimonialWrapper2Type=='training'">
  <div class="container">
      <div class="row mb-5">
        <div class="col-12 text-center heading-wrapper mb-3">
            <div class="w-75 m-auto">
              <h4 class="h2 mb-3" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" >
                  Reviews by<span class="chatbot-text-primary"> Students</span>
              </h4>
              <p class="text-muted" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="200">
                  We will be happy to organize a 30 minute demo to showcase why the industry leaders prefer all in one for Conversational AI platform.
              </p>
            </div>
        </div>
      </div>
    <div class="row" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="500">
      <div class="col-12">
        <owl-carousel-o [options]="testimonial1">
          <ng-template carouselSlide>
            <div class="testimonial-item">
              <div class="profile-img float-start">
                  <img src="./assets/images/users/2.png" />
              </div>
              <div class="testominial-text float-end">
                <p>
                  “The most well-known dum text is the 'Lorem Ipsum', which is said to have originated in the 16th century. ".
                </p>
                <div class="testi-meta mt-2">
                  <span class="float-start w-100 mb-2">
                      <span class="chatbot-text-primary">- Ana Watson </span>
                      <span> Syntel Inc</span></span>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="testimonial-item">
              <div class="profile-img float-start">
                  <img src="./assets/images/users/1.png" />
              </div>
              <div class="testominial-text float-end">
                <p>
                  "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "
                </p>
                <div class="testi-meta mt-2">
                  <span class="float-start w-100 mb-2">
                      <span class="chatbot-text-primary">- Matrik Lee  </span>
                      <span> Apple Inc</span></span>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="testimonial-item">
              <div class="profile-img float-start">
                  <img src="./assets/images/users/3.png" />
              </div>
              <div class="testominial-text float-end">
                <p>
                  "One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "
                </p>
                <div class="testi-meta mt-2">
                  <span class="float-start w-100 mb-2">
                      <span class="chatbot-text-primary">- Jolly Wilson  </span>
                      <span>City Bank, India</span></span>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="testimonial-item">
              <div class="profile-img float-start">
                  <img src="./assets/images/users/1.png" />
              </div>
              <div class="testominial-text float-end">
                <p>
                  “The most well-known dum text is the 'Lorem Ipsum', which is said to have originated in the 16th century. ".
                </p>
                <div class="testi-meta mt-2">
                  <span class="float-start w-100 mb-2">
                      <span class="chatbot-text-primary">- Ana Watson </span>
                      <span> Syntel Inc</span>
                  </span>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- image-text-wrapper3 section end here -->
  