<nav class="navbar navbar-expand-lg static-top common-header2" *ngIf="header4Type=='training'" >
    <div class="container">
        <!-- Brand -->
      <a class="navbar-brand" [routerLink]="['/corporate-blue-home']">
        <img src="./assets/images/training/logo-animated.jpg" alt="logo" />
      </a>
      <!-- Toggler -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon">
            <i class="ri-menu-3-line"></i>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" [routerLink]="['/training-home']">Home</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Courses
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <li>
                    <a class="dropdown-item" [routerLink]="['/training-course-details-ux']">
                        <i class="ri-bubble-chart-fill float-start"></i> Python
                    </a>
                </li>
                <li>
                    <a class="dropdown-item" [routerLink]="['/training-course-details-ux']">
                        <i class="ri-download-cloud-line float-start"></i> AWS
                    </a>
                </li>
                <li>
                    <a class="dropdown-item" [routerLink]="['/training-course-details-ux']">
                        <i class="ri-download-cloud-line float-start"></i> UX/UI Designing
                    </a>
                </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link active" aria-current="page">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>