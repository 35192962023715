import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TrainingHomeComponent } from './pages/views/training/training-home/training-home.component';
import { TrainingCourseDetailsUxComponent } from './pages/views/training/training-course-details-ux/training-course-details-ux.component';
import { TrainingCourseDetailsAwsComponent } from './pages/views/training/training-course-details-aws/training-course-details-aws.component';
import { CourseDetailUxuiOverviewComponent } from './pages/views/training-details/design-process/course-detail-uxui-overview/course-detail-uxui-overview.component';
import { OverviewDesignProcessComponent } from './pages/views/training-details/design-process/overview-design-process/overview-design-process.component';
import { DiffBtwUxUiComponent } from './pages/views/training-details/design-process/diff-btw-ux-ui/diff-btw-ux-ui.component';
import { IntroInterviewQsnAnsComponent } from './pages/views/training-details/design-process/intro-interview-qsn-ans/intro-interview-qsn-ans.component';
import { DesignProcessAssignmentsComponent } from './pages/views/training-details/design-process/design-process-assignments/design-process-assignments.component';
import { DesignPrincipleComponent } from './pages/views/training-details/design-theory/design-principle/design-principle.component';
import { TypographyComponent } from './pages/views/training-details/design-theory/typography/typography.component';
import { IconographyComponent } from './pages/views/training-details/design-theory/iconography/iconography.component';
import { TrainingCourseDetailsDevOpsComponent } from './pages/views/training/training-course-details-dev-ops/training-course-details-dev-ops.component';

const routes: Routes = [
  { path: 'iconography', component: IconographyComponent },
  { path: 'typography', component: TypographyComponent },
  { path: 'design-principle', component: DesignPrincipleComponent },
  { path: 'design-process-assignments', component: DesignProcessAssignmentsComponent },
  { path: 'intro-interview-qsn-ans', component: IntroInterviewQsnAnsComponent },
  { path: 'diff-btw-ux-ui', component: DiffBtwUxUiComponent },
  { path: 'Overview-design-process', component: OverviewDesignProcessComponent },
  { path: 'course-detail-uxui-overview', component: CourseDetailUxuiOverviewComponent },
  { path: 'training-home', component: TrainingHomeComponent },
  { path: 'training-course-details-ux', component: TrainingCourseDetailsUxComponent },
  { path: 'training-course-details-aws', component: TrainingCourseDetailsAwsComponent },
  { path: 'training-course-details-devops', component: TrainingCourseDetailsDevOpsComponent },
  { path: '**', component: TrainingHomeComponent },
  { path: '', redirectTo: 'training-home', pathMatch: 'full' },
];

@NgModule({
  declarations: [
  ],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
