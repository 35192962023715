import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { Header1Component } from './pages/common/header/header1/header1.component';
import { Header2Component } from './pages/common/header/header2/header2.component';
import { Header3Component } from './pages/common/header/header3/header3.component';
import { Header4Component } from './pages/common/header/header4/header4.component';
import { Footer1Component } from './pages/common/footer/footer1/footer1.component';
import { Footer2Component } from './pages/common/footer/footer2/footer2.component';
import { TopBanner1Component } from './pages/common/top-banner/top-banner1/top-banner1.component';
import { TopBanner2Component } from './pages/common/top-banner/top-banner2/top-banner2.component';
import { InfoWrapper1Component } from './pages/common/info-wrapper/info-wrapper1/info-wrapper1.component';
import { InfoWrapper2Component } from './pages/common/info-wrapper/info-wrapper2/info-wrapper2.component';
import { ImageTextWrapper1Component } from './pages/common/image-text-wrapper/image-text-wrapper1/image-text-wrapper1.component';
import { ImageTextWrapper2Component } from './pages/common/image-text-wrapper/image-text-wrapper2/image-text-wrapper2.component';
import { ImageTextWrapper3Component } from './pages/common/image-text-wrapper/image-text-wrapper3/image-text-wrapper3.component';
import { ImageTextWrapper4Component } from './pages/common/image-text-wrapper/image-text-wrapper4/image-text-wrapper4.component';
import { ImageTextWrapper5Component } from './pages/common/image-text-wrapper/image-text-wrapper5/image-text-wrapper5.component';
import { ImageTextWrapper6Component } from './pages/common/image-text-wrapper/image-text-wrapper6/image-text-wrapper6.component';
import { CarouselWrapper1Component } from './pages/common/carousel/carousel-wrapper1/carousel-wrapper1.component';
import { CarouselWrapper2Component } from './pages/common/carousel/carousel-wrapper2/carousel-wrapper2.component';
import { CarouselWrapper3Component } from './pages/common/carousel/carousel-wrapper3/carousel-wrapper3.component';
import { PricePlanWrapper1Component } from './pages/common/price-plan/price-plan-wrapper1/price-plan-wrapper1.component';
import { HelpWrapper1Component } from './pages/common/help/help-wrapper1/help-wrapper1.component';
import { Testimonial1Component } from './pages/common/testimonial/testimonial1/testimonial1.component';
import { Testimonial2Component } from './pages/common/testimonial/testimonial2/testimonial2.component';
import { GoTop1Component } from './pages/common/go-top/go-top1/go-top1.component';
import { FaqWrapper1Component } from './pages/common/faq/faq-wrapper1/faq-wrapper1.component';
import { Tab1Component } from './pages/common/tab/tab1/tab1.component';
import { Subscribe1Component } from './pages/common/subscribe/subscribe1/subscribe1.component';
import { WhyWrapper1Component } from './pages/common/why/why-wrapper1/why-wrapper1.component';
import { Testimonial3Component } from './pages/common/testimonial/testimonial3/testimonial3.component';
import { Schedule1Component } from './pages/common/schedule/schedule1/schedule1.component';
import { Blog1Component } from './pages/common/blog/blog1/blog1.component';
import { TopBanner3Component } from './pages/common/top-banner/top-banner3/top-banner3.component';
import { CarouselWrapper4Component } from './pages/common/carousel/carousel-wrapper4/carousel-wrapper4.component';
import { VideoPlayer1Component } from './pages/common/video-player/video-player1/video-player1.component';
import { Accordion1Component } from './pages/common/accordion/accordion1/accordion1.component';
import { Modal1Component } from './pages/common/modal/modal1/modal1.component';
import { Modal2Component } from './pages/common/modal/modal2/modal2.component';
import { Sidebar1Component } from './pages/common/sidebar/sidebar1/sidebar1.component';
import { Footer3Component } from './pages/common/footer/footer3/footer3.component';
import { InfoWrapper3Component } from './pages/common/info-wrapper/info-wrapper3/info-wrapper3.component';
import { TrainingHomeComponent } from './pages/views/training/training-home/training-home.component';
import { TopBanner4Component } from './pages/common/top-banner/top-banner4/top-banner4.component';
import { TrainingCourseDetailsUxComponent } from './pages/views/training/training-course-details-ux/training-course-details-ux.component';
import { CourseDetailUxuiOverviewComponent } from './pages/views/training-details/design-process/course-detail-uxui-overview/course-detail-uxui-overview.component';
import { OverviewDesignProcessComponent } from './pages/views/training-details/design-process/overview-design-process/overview-design-process.component';
import { DiffBtwUxUiComponent } from './pages/views/training-details/design-process/diff-btw-ux-ui/diff-btw-ux-ui.component';
import { IntroInterviewQsnAnsComponent } from './pages/views/training-details/design-process/intro-interview-qsn-ans/intro-interview-qsn-ans.component';
import { DesignProcessAssignmentsComponent } from './pages/views/training-details/design-process/design-process-assignments/design-process-assignments.component';
import { DesignPrincipleComponent } from './pages/views/training-details/design-theory/design-principle/design-principle.component';
import { TypographyComponent } from './pages/views/training-details/design-theory/typography/typography.component';
import { IconographyComponent } from './pages/views/training-details/design-theory/iconography/iconography.component';
import { TrainingCourseDetailsAwsComponent } from './pages/views/training/training-course-details-aws/training-course-details-aws.component';
import { TrainingCourseDetailsDevOpsComponent } from './pages/views/training/training-course-details-dev-ops/training-course-details-dev-ops.component';


@NgModule({
  declarations: [
    AppComponent,
    Header1Component,
    Header2Component,
    Footer1Component,
    Footer2Component,
    TopBanner1Component,
    InfoWrapper1Component,
    InfoWrapper2Component,
    ImageTextWrapper1Component,
    ImageTextWrapper2Component,
    ImageTextWrapper3Component,
    ImageTextWrapper4Component,
    CarouselWrapper1Component,
    CarouselWrapper2Component,
    PricePlanWrapper1Component,
    HelpWrapper1Component,
    Testimonial1Component,
    GoTop1Component,
    FaqWrapper1Component,
    ImageTextWrapper5Component,
    Header3Component,
    TopBanner2Component,
    ImageTextWrapper6Component,
    CarouselWrapper3Component,
    Tab1Component,
    Testimonial2Component,
    Subscribe1Component,
    WhyWrapper1Component,
    Testimonial3Component,
    Schedule1Component,
    Blog1Component,
    TopBanner3Component,
    CarouselWrapper4Component,
    VideoPlayer1Component,
    Accordion1Component,
    Modal1Component,
    Modal2Component,
    Sidebar1Component,
    Footer3Component,
    InfoWrapper3Component,
    TrainingHomeComponent,
    TopBanner4Component,
    Header4Component,
    TrainingCourseDetailsUxComponent,
    CourseDetailUxuiOverviewComponent,
    OverviewDesignProcessComponent,
    DiffBtwUxUiComponent,
    IntroInterviewQsnAnsComponent,
    DesignProcessAssignmentsComponent,
    DesignPrincipleComponent,
    TypographyComponent,
    IconographyComponent,
    TrainingCourseDetailsAwsComponent,
    TrainingCourseDetailsDevOpsComponent
  ],
  imports: [
		FormsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    CarouselModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
