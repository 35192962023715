
<app-header4 [header4Type]="'training'"></app-header4>
<!-- Header2 end here-->

<!-- Top banner start here-->
<app-top-banner4 [topbanner4Type]="'training'"></app-top-banner4>
<!-- Top banner end here-->






<div class="edu-categorie-area categorie-area-1 edu-section-gap">
    <div class="container">
        <div class="section-title section-center sal-animate text-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
            <h4 class="title mb-5" ta-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">Online <span class="color-primary">Classes</span> For Remote Learning.</h4>
            <span class="shape-line"><i class="icon-19"></i></span>
        </div>
        <div class="row g-5">
            <div class="col-lg-3 col-sm-6 sal-animate" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                <div class="categorie-grid categorie-style-1 color-primary-style edublink-svg-animate cursor-pointer" [routerLink]="['/training-course-details-aws']">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="40" viewBox="0 0 34 40" fill="none" data-inject-url="https://edublink.html.devsblink.com/assets/images/animated-svg-icons/online-degree.svg" class="svgInject">
<path d="M7.70459 25.5939L12.8535 30.8403C13.015 31.0021 13.11 31.2307 13.11 31.4592V35.0298C13.11 35.4106 13.3475 35.7439 13.699 35.8676C15.0575 36.3342 18.4679 36.7341 20.9664 38.8574C21.5364 39.3335 22.4009 38.905 22.4009 38.1719V34.3918C22.4009 32.9921 23.4744 31.8496 24.8613 31.7067C27.1033 31.4592 30.0483 29.9452 29.8773 23.6325C29.8678 23.1373 30.2763 22.7279 30.7702 22.7279H31.8247C32.8507 22.7279 33.4112 21.5377 32.7557 20.7474L29.6873 17.015C29.3928 16.6627 29.2313 16.2057 29.2313 15.7486V10.2833C29.2503 6.19852 25.8683 0.952148 19.4559 0.952148C13.0435 0.952148 8.72108 3.58009 8.72108 6.4556" stroke="#1ab69d" stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round" style="stroke-dasharray: 86, 88; stroke-dashoffset: 0;"></path>
<path d="M24.1014 5.98926V23.9945C24.1014 24.9752 23.3129 25.7655 22.3344 25.7655H20.1779C19.9499 25.7655 19.7409 25.8798 19.6269 26.0797L17.9739 28.7648C17.9169 28.8505 17.8029 28.8695 17.7269 28.8029L14.554 25.9369C14.4305 25.8322 14.2785 25.7655 14.117 25.7655H2.71717C1.73868 25.7655 0.950195 24.9657 0.950195 23.9945V7.88404C0.950195 6.90333 1.73868 6.11304 2.71717 6.11304H18.7624" stroke="#1ab69d" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 81, 83; stroke-dashoffset: 0;"></path>
<path d="M12.2168 17.5674C13.1717 17.5674 13.9457 16.7915 13.9457 15.8345C13.9457 14.8774 13.1717 14.1016 12.2168 14.1016C11.2619 14.1016 10.4878 14.8774 10.4878 15.8345C10.4878 16.7915 11.2619 17.5674 12.2168 17.5674Z" stroke="#1ab69d" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 11, 13; stroke-dashoffset: 0;"></path>
<path d="M7.70456 12.9688C8.65945 12.9688 9.43354 12.1929 9.43354 11.2359C9.43354 10.2788 8.65945 9.50293 7.70456 9.50293C6.74967 9.50293 5.97559 10.2788 5.97559 11.2359C5.97559 12.1929 6.74967 12.9688 7.70456 12.9688Z" stroke="#1ab69d" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 11, 13; stroke-dashoffset: 0;"></path>
<path d="M16.7295 12.9688C17.6843 12.9688 18.4584 12.1929 18.4584 11.2359C18.4584 10.2788 17.6843 9.50293 16.7295 9.50293C15.7746 9.50293 15.0005 10.2788 15.0005 11.2359C15.0005 12.1929 15.7746 12.9688 16.7295 12.9688Z" stroke="#1ab69d" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 11, 13; stroke-dashoffset: 0;"></path>
<path d="M7.70456 22.167C8.65945 22.167 9.43354 21.3912 9.43354 20.4341C9.43354 19.477 8.65945 18.7012 7.70456 18.7012C6.74967 18.7012 5.97559 19.477 5.97559 20.4341C5.97559 21.3912 6.74967 22.167 7.70456 22.167Z" stroke="#1ab69d" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 11, 13; stroke-dashoffset: 0;"></path>
<path d="M16.7295 22.167C17.6843 22.167 18.4584 21.3912 18.4584 20.4341C18.4584 19.477 17.6843 18.7012 16.7295 18.7012C15.7746 18.7012 15.0005 19.477 15.0005 20.4341C15.0005 21.3912 15.7746 22.167 16.7295 22.167Z" stroke="#1ab69d" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 11, 13; stroke-dashoffset: 0;"></path>
<path d="M13.3472 14.5296L15.4656 12.4062" stroke="#1ab69d" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 3, 5; stroke-dashoffset: 0;"></path>
<path d="M10.9152 14.7009L8.73975 12.5205" stroke="#1ab69d" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 4, 6; stroke-dashoffset: 0;"></path>
<path d="M10.8582 17.7295L8.73975 19.8528" stroke="#1ab69d" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 3, 5; stroke-dashoffset: 0;"></path>
<path d="M13.2808 17.5576L15.4657 19.738" stroke="#1ab69d" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 4, 6; stroke-dashoffset: 0;"></path>
<path d="M21.4507 6.55079C21.692 6.55079 21.8877 6.35469 21.8877 6.1128C21.8877 5.8709 21.692 5.6748 21.4507 5.6748C21.2093 5.6748 21.0137 5.8709 21.0137 6.1128C21.0137 6.35469 21.2093 6.55079 21.4507 6.55079Z" stroke="#1ab69d" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 3, 5; stroke-dashoffset: 0;"></path>
</svg>
                    </div>
                    <div class="content">
                        <p class="font-20">AWS Architect Associate</p>
                        <p class="font-16">Lorem ipsum dolor consec tur elit adicing sed umod tempor.</p>
                        <div class="course-remain">7 Courses</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 sal-animate" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                <div class="categorie-grid categorie-style-1 color-secondary-style edublink-svg-animate cursor-pointer" [routerLink]="['/training-course-details-devops']">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="40" viewBox="0 0 45 40" fill="none" data-inject-url="https://edublink.html.devsblink.com/assets/images/animated-svg-icons/non-degree-programs.svg" class="svgInject">
<path d="M26.0913 17.2855L23.2222 12.495C22.8949 11.9522 22.1054 11.9522 21.778 12.495L14.4032 24.7712C14.2684 24.9998 14.2492 25.2855 14.3551 25.5236L17.0412 31.6569C17.099 31.7998 17.1279 31.9522 17.099 32.1046L16.2036 38.095C16.1266 38.5998 16.5213 39.0474 17.0316 39.0474H22.4905H27.9494C28.4597 39.0474 28.8544 38.5998 28.7774 38.095L27.882 32.1236C27.8628 31.9712 27.882 31.8093 27.9398 31.676L30.6259 25.5427C30.7318 25.295 30.7126 25.0188 30.5778 24.7903L28.6426 21.5712" stroke="#ee4a62" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" style="stroke-dasharray: 68, 70; stroke-dashoffset: 0;"></path>
<path d="M16.252 31.9902H28.7487" stroke="#ee4a62" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" style="stroke-dasharray: 13, 15; stroke-dashoffset: 0;"></path>
<path d="M22.4999 27.9713C24.2546 27.9713 25.6771 26.5642 25.6771 24.8284C25.6771 23.0927 24.2546 21.6855 22.4999 21.6855C20.7452 21.6855 19.3228 23.0927 19.3228 24.8284C19.3228 26.5642 20.7452 27.9713 22.4999 27.9713Z" stroke="#ee4a62" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 20, 22; stroke-dashoffset: 0;"></path>
<path d="M22.5 12.0947V18.1043" stroke="#ee4a62" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 7, 9; stroke-dashoffset: 0;"></path>
<path d="M22.4999 7.84739C24.4247 7.84739 25.9851 6.30383 25.9851 4.39977C25.9851 2.4957 24.4247 0.952148 22.4999 0.952148C20.575 0.952148 19.0146 2.4957 19.0146 4.39977C19.0146 6.30383 20.575 7.84739 22.4999 7.84739Z" stroke="#ee4a62" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 22, 24; stroke-dashoffset: 0;"></path>
<path d="M5.01611 4.39941H19.0148" stroke="#ee4a62" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 14, 16; stroke-dashoffset: 0;"></path>
<path d="M2.98471 6.39941C4.10134 6.39941 5.00654 5.50398 5.00654 4.39941C5.00654 3.29484 4.10134 2.39941 2.98471 2.39941C1.86809 2.39941 0.962891 3.29484 0.962891 4.39941C0.962891 5.50398 1.86809 6.39941 2.98471 6.39941Z" stroke="#ee4a62" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 13, 15; stroke-dashoffset: 0;"></path>
<path d="M8.19321 18.8184C9.30983 18.8184 10.215 17.9229 10.215 16.8184C10.215 15.7138 9.30983 14.8184 8.19321 14.8184C7.07659 14.8184 6.17139 15.7138 6.17139 16.8184C6.17139 17.9229 7.07659 18.8184 8.19321 18.8184Z" stroke="#ee4a62" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 13, 15; stroke-dashoffset: 0;"></path>
<path d="M8.19336 15.0947C8.19336 9.18037 13.0265 4.39941 19.0053 4.39941" stroke="#ee4a62" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 17, 19; stroke-dashoffset: 0;"></path>
<path d="M39.9841 4.39941H25.9854" stroke="#ee4a62" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 14, 16; stroke-dashoffset: 0;"></path>
<path d="M42.0057 6.39941C43.1223 6.39941 44.0275 5.50398 44.0275 4.39941C44.0275 3.29484 43.1223 2.39941 42.0057 2.39941C40.8891 2.39941 39.9839 3.29484 39.9839 4.39941C39.9839 5.50398 40.8891 6.39941 42.0057 6.39941Z" stroke="#ee4a62" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 13, 15; stroke-dashoffset: 0;"></path>
<path d="M36.7972 18.8184C37.9138 18.8184 38.819 17.9229 38.819 16.8184C38.819 15.7138 37.9138 14.8184 36.7972 14.8184C35.6806 14.8184 34.7754 15.7138 34.7754 16.8184C34.7754 17.9229 35.6806 18.8184 36.7972 18.8184Z" stroke="#ee4a62" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 13, 15; stroke-dashoffset: 0;"></path>
<path d="M36.7973 15.0947C36.7973 9.18037 31.9642 4.39941 25.9854 4.39941" stroke="#ee4a62" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 17, 19; stroke-dashoffset: 0;"></path>
<path d="M27.333 19.7903C27.5776 19.7903 27.7759 19.5941 27.7759 19.3522C27.7759 19.1102 27.5776 18.9141 27.333 18.9141C27.0884 18.9141 26.8901 19.1102 26.8901 19.3522C26.8901 19.5941 27.0884 19.7903 27.333 19.7903Z" stroke="#ee4a62" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 3, 5; stroke-dashoffset: 0;"></path>
</svg>
                    </div>
                    <div class="content">
                        <p class="font-20">DevOps</p>
                        <p class="font-16">Lorem ipsum dolor consec tur elit adicing sed umod tempor.</p>
                        <div class="course-remain">4 Courses</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 sal-animate" data-sal-delay="300" data-sal="slide-up" data-sal-duration="800">
                <div class="categorie-grid categorie-style-1 color-extra08-style edublink-svg-animate cursor-pointer">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" data-inject-url="https://edublink.html.devsblink.com/assets/images/animated-svg-icons/off-campus-programs.svg" class="svgInject">
<path d="M38.095 39.0474H1.90453C1.38072 39.0474 0.952148 38.6188 0.952148 38.095V1.90453C0.952148 1.38072 1.38072 0.952148 1.90453 0.952148H38.095C38.6188 0.952148 39.0474 1.38072 39.0474 1.90453V38.095C39.0474 38.6188 38.6188 39.0474 38.095 39.0474Z" stroke="#4664e4" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 151, 153; stroke-dashoffset: 0;"></path>
<path d="M0.952148 9.75195H39.0474" stroke="#4664e4" stroke-width="2" stroke-miterlimit="10" style="stroke-dasharray: 39, 41; stroke-dashoffset: 0;"></path>
<path d="M14.3997 18.2383L9.35205 24.0859L13.9997 29.924" stroke="#4664e4" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 16, 18; stroke-dashoffset: 0;"></path>
<path d="M24.0664 18.2383L29.1045 24.0859L24.4664 29.924" stroke="#4664e4" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 16, 18; stroke-dashoffset: 0;"></path>
<path d="M21.4379 18.2383L16.9141 29.924" stroke="#4664e4" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 13, 15; stroke-dashoffset: 0;"></path>
<path d="M5.78086 6.00899C6.0123 6.00899 6.19991 5.82138 6.19991 5.58995C6.19991 5.35851 6.0123 5.1709 5.78086 5.1709C5.54943 5.1709 5.36182 5.35851 5.36182 5.58995C5.36182 5.82138 5.54943 6.00899 5.78086 6.00899Z" stroke="#4664e4" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 3, 5; stroke-dashoffset: 0;"></path>
<path d="M9.42832 6.00899C9.65976 6.00899 9.84737 5.82138 9.84737 5.58995C9.84737 5.35851 9.65976 5.1709 9.42832 5.1709C9.19689 5.1709 9.00928 5.35851 9.00928 5.58995C9.00928 5.82138 9.19689 6.00899 9.42832 6.00899Z" stroke="#4664e4" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 3, 5; stroke-dashoffset: 0;"></path>
<path d="M13.0665 6.00899C13.2979 6.00899 13.4856 5.82138 13.4856 5.58995C13.4856 5.35851 13.2979 5.1709 13.0665 5.1709C12.8351 5.1709 12.6475 5.35851 12.6475 5.58995C12.6475 5.82138 12.8351 6.00899 13.0665 6.00899Z" stroke="#4664e4" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" style="stroke-dasharray: 3, 5; stroke-dashoffset: 0;"></path>
</svg>
                    </div>
                    <div class="content">
                        <p class="font-20">Python</p>
                        <p class="font-16">Lorem ipsum dolor consec tur elit adicing sed umod tempor.</p>
                        <div class="course-remain">8 Courses</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 sal-animate" data-sal-delay="400" data-sal="slide-up" data-sal-duration="800">
                <div class="categorie-grid categorie-style-1 color-extra05-style edublink-svg-animate cursor-pointer" [routerLink]="['/training-course-details-aws']" >
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" data-inject-url="https://edublink.html.devsblink.com/assets/images/animated-svg-icons/hybrid-distance-programs.svg" class="svgInject">
<path d="M3.51448 30.4573C0.0954334 27.0383 0.0954334 21.4954 3.51448 18.0764L18.0764 3.51448C21.4954 0.0954334 27.0383 0.0954334 30.4573 3.51448C33.8764 6.93353 33.8764 12.4764 30.4573 15.8954L15.8954 30.4573C12.4764 33.8764 6.93353 33.8764 3.51448 30.4573Z" stroke="#f8941f" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" style="stroke-dasharray: 97, 99; stroke-dashoffset: 0;"></path>
<path d="M31.3715 39.0477C35.6109 39.0477 39.0477 35.6109 39.0477 31.3715C39.0477 27.1321 35.6109 23.6953 31.3715 23.6953C27.1321 23.6953 23.6953 27.1321 23.6953 31.3715C23.6953 35.6109 27.1321 39.0477 31.3715 39.0477Z" stroke="#f8941f" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" style="stroke-dasharray: 49, 51; stroke-dashoffset: 0;"></path>
<path d="M10.7905 10.791L23.1715 23.172" stroke="#f8941f" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" style="stroke-dasharray: 18, 20; stroke-dashoffset: 0;"></path>
<path d="M25.9619 36.781L36.781 25.9619" stroke="#f8941f" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" style="stroke-dasharray: 16, 18; stroke-dashoffset: 0;"></path>
<path d="M19.5337 9.57139L21.9527 7.15234" stroke="#f8941f" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" style="stroke-dasharray: 4, 6; stroke-dashoffset: 0;"></path>
</svg>
                    </div>
                    <div class="content">
                        <p class="font-20">UX Designing</p>
                        <p class="font-16">Lorem ipsum dolor consec tur elit adicing sed umod tempor.</p>
                        <div class="course-remain">8 Courses</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>







<!-- carousel-wrapper3 start here-->
<app-carousel-wrapper3 [carouselWrapper3Type]="'training'"></app-carousel-wrapper3>
<!-- carousel-wrapper3 start here-->

<!-- app-testimonial2 start here-->
<app-testimonial2 [testimonialWrapper2Type]="'training'"></app-testimonial2>
<!-- app-testimonial2 start here-->

<!-- Footer1 section start here -->
<app-footer1 [footer1Type]="'training'"></app-footer1>
<!-- Footer1 section end here -->

<!-- Go-top1 section start here -->
<app-go-top1></app-go-top1>
<!-- Go-top1 section end here -->